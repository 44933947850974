@tailwind base;
@tailwind components;
@tailwind utilities;

.cluster {
  @apply relative;
  @apply bg-black;
  @apply text-white;
  @apply w-10;
  @apply h-10;
  @apply rounded-full;
  @apply text-center;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply text-xs;
  @apply font-bold;

  &::before {
    @apply absolute;
    @apply rounded-full;
    @apply bg-black;
    @apply opacity-50;
    @apply -top-1;
    @apply -right-1;
    @apply -bottom-1;
    @apply -left-1;
    @apply -z-10;
    content: '';
  }
}
